.product-item {
  background-color: $bg-main;
  border: 1px solid $border-light;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: $grid-gutter-width;
  text-decoration: none;
  color: inherit;
  box-shadow: 0 0 4px #1919191c;
  &.inactive {
    opacity: 0.5;
  }
  .item-image {
    width: 100px;
    height: 100px;
    border-radius: $border-radius;
    background-color: $bg-base;
    margin-right: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }
  .item-body {
    flex: 1;
    padding: 0.25rem 0 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }

  .item-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }

  .item-name {
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0.25rem;
    text-overflow: ellipsis;
  }
  .item-desc {
    margin-bottom: 0;
    @include line-clamp($font-size-base - 0.125, $line-height-base, 2);
  }
  .item-price {
    font-weight: bold;
    color: #007d4a;
  }
}
.used-in-products {
  padding: 0;
  font-size: 1rem;
  list-style: none;
  b {
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  ul {
    list-style-type: none;
    padding: 0 0 0 1rem;
    font-size: 0.95em;
  }
}

.basePriceWrapper {
  .form-group {
    width: 100%;
  }

  .sizeTypeModifiers {
    display: flex;
    align-items: center;
  }

  .basePriceButtons {
    display: flex;
    padding-left: 5px;
    margin-bottom: 8px;
  }
  .errorState {
    color: red;
    border: 1px solid red;
  }
}

.modifer-drag {
  padding: 4px;
}
