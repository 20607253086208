.tutorial-item {
  margin-bottom: 1.5rem;

  iframe {
    width: 100%;
    margin-bottom: 0.5rem;
    &:focus {
      outline: none;
    }
  }
}
