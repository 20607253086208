.provider-item {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  background-color: $bg-main;
  .custom-switch {
    label,
    input {
      cursor: pointer;
    }
  }
  .provider-img {
    padding: 1rem;
    p {
      font-size: 18px;
      font-weight: 500;

      margin-bottom: 0;
      padding: 0 12px;
    }
  }
  .provider-item-inner {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    border-top: 1px solid #ccc;
    padding: 0.75rem 1rem;
    .custom-switch {
      min-width: 192px;
    }

    p {
      margin-bottom: 0;
      margin-right: auto;
      font-weight: 500;
    }

    .btn {
      margin: 0.5rem 0;
      margin-left: auto;
    }
  }
}
.locationsModal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
