.modifiers-list {
  .modifiers-list-item-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.modifier-item {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  background-color: $bg-main;
  margin-bottom: 1rem;
  padding: 0.75rem;
  position: relative;
  flex: 1;
  &.active {
    border-color: $primary;
  }
  .checked-icon {
    color: $primary;
    margin: 0.5rem;
  }
  .checked-icon,
  .item-actions {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    z-index: 2;
  }
  .item-edit {
    right: 4rem;
  }
  h6 {
    margin-bottom: 0;
    padding-right: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    hyphens: auto;
  }
  .item-options {
    border-top: 1px solid $border-color;
    margin-top: 0.5rem;
    padding: 0.5rem 0 0;
    margin-bottom: 0;
    list-style-type: none;
    font-size: 0.875rem;
    .option-subtitle {
      flex: 1 1;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-right: 20px;
      hyphens: auto;
    }
    li {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }
}

.modifier-info {
  margin-bottom: 1rem;
}
.modifier-search {
  justify-content: flex-end;
  margin-bottom: 1rem;
}
