.menu-tab-content {
  background-color: $body-bg;
  border: 1px solid $border-color;
  border-top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 $border-radius $border-radius;
}
.menu-header {
  border-bottom: 1px solid $border-color;
  padding: 0 1rem - $btn-padding-x 0 1rem;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .divider {
    height: 2rem;
    margin: 0 0.5rem;
    border-left: 1px solid $border-color;
  }
}
.menu-content-wrapper {
  flex: 1;
  display: flex;
  align-self: stretch;
  background-color: $bg-main;
  border-radius: 0 0 $border-radius $border-radius;
}
.menu-content {
  position: relative;
  flex: 1;
}
.menu-content-header {
  margin: 0 1rem;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-color;
  justify-content: space-between;
}
.menu-content-body {
  height: 100%;
  padding: 1rem;
  .row {
    height: 100%;
  }
}
