.menu-categories {
  width: 220px;
  border-right: 1px solid $border-color;
  .menu-categories-header {
    border-bottom: 1px solid $border-color;
    padding: 0 1rem - $btn-padding-x 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
  }
  .menu-categories-list {
    .nav-item {
      width: 100%;
    }
    .nav-item:last-child .nav-link {
      border-bottom: 0;
    }
  }
}

.menu-categories-list {
  .menu-content {
    margin: 20% 0;
  }
}

.nav-item-draggable {
  .nav-link {
    color: inherit;
    border-bottom: 1px solid $border-color;
    position: relative;
    line-height: 2.125rem;
    transition: background-color 0.2s;
    min-width: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .drag-handle {
      margin-right: 0.75rem;
      margin-left: -0.25rem;
    }
    &:after {
      content: '';
      position: absolute;
      transform: scaleX(0);
      display: block;
      width: 4px;
      top: -1px;
      bottom: -1px;
      z-index: 2;
      right: 0;
      background-color: $bg-dark;
      transition: transform 0.2s;
      transform-origin: 100% 100%;
    }
    &:hover {
      background-color: #e5eaf1;
    }
    &.active {
      background-color: #e5eaf1;
      &:after {
        transform: scaleX(1);
      }
    }
  }
}

body > .nav-item-draggable .nav-link {
  border-top: 1px solid $border-color;
  background-color: $bg-main;
}
