.menu-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
    border-radius: $border-radius/2;
    border: 1px solid $border-light;
    background-color: white;
    margin: 0 2px;
    padding: 1px;
  }
}
