.empty-menu {
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  .empty-content {
    max-width: 430px;
    align-items: center;
    justify-content: center;
    text-align: center;
    .image {
      width: 239px;
      height: 178px;
    }
  }
}
