.app-sidebar {
  background-color: $sidebar-bg;
  color: $sidebar-color;
  width: $sidebar-width;

  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 20;
  box-shadow: $box-shadow;
  .sidebar-header {
    padding: 1rem;
  }
  .sidebar-brand {
    display: flex;
    align-items: center;
    color: inherit;
    font-weight: $font-weight-bold;
    img {
      height: 40px;
      margin-right: 1rem;
    }
  }
  .sidebar-body {
    flex: 1;
    padding: 0 0 1rem;
  }
  .nav-link {
    color: inherit;
    padding: 1rem;
    display: flex;
    align-items: center;
    i {
      margin-right: 1rem;
      vertical-align: top;
      font-size: 20px;
    }
    &.active {
      background-color: $sidebar-bg-active;
      color: inherit;
    }
  }
  .report-icon {
    margin-right: 20px;
  }
}
