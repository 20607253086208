.aside-view {
  width: 432px;
  background-color: $bg-main;
  z-index: 50;
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: sticky;
  top: 0;
  // height: 90vh;
  height: calc(100vh - 56px);
  z-index: 50;
  &.report-view {
    top: 0;
    bottom: 0;
    right: 0;
    background-color: $bg-main;
    z-index: 777;
    flex: 1;
    position: fixed;
    width: 456px;
    height: 100%;
  }
  .tab-content {
    height: 100%;
    // height: 90vh;
    display: grid;
    width: 100%;
    padding: 0;
  }
  .tab-content > .active {
    overflow: auto;
  }
  .aside-view-tab {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .aside-view-body {
    padding: 1rem !important;

    .p-text-warning {
      color: #ac001d !important;
    }
  }
  .aside-view-body {
    flex: 1;
    overflow: auto;
  }

  .text {
    font-size: 12px;
    color: $text-muted;
    font-weight: 'bold';
  }
  .table .table {
    font-size: 0.875rem;
    td {
      padding: 0.25rem 0.5rem;
    }
  }
  .total-table {
    border-top-width: 2px !important;
    .total-row {
      border-top: 1px solid $border-color;
    }
  }
}
.aside-view-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: $box-shadow-sm;
  position: relative;
  z-index: 12;
  height: 3.75rem;
  padding: 0 1rem;
  h6 {
    margin: 0;
  }
  .btn {
    padding: 0;
  }
}
.details-title {
  background-color: #f5e5d3;
  color: #0f1214;
  padding: 16px 24px;
  margin: -16px;
  .details-text {
    font-size: 18px;
  }
}

.detail-info-message {
  margin: 32px 0;
  .details-text {
    font-weight: 900;
    font-size: 22px;
  }
}

.details-type {
  margin-top: 46px;
  color: #0f1214;
}

.details-scheduled {
  margin-top: 32px;
  background-color: #d1ecf1;
  border-radius: 6px;
  padding: 16px;
}

.aside-view-detailers {
  padding-top: 30px;
  padding-bottom: 1rem;
  display: flex;
  .icon {
    font-size: 12px;
    margin-right: 0.5rem;
    color: $text-muted;
  }
  .detailers {
    padding-left: 1rem;
    p {
      margin: 0;
    }
  }
}
.order-date {
  display: flex;
  align-items: center;
}
.aside-view-actions {
  display: flex;
  padding: 1rem 0.5rem;
  justify-content: space-between;
  position: relative;
  box-shadow: 0 -2px 4px rgba(black, 0.1);
  &:empty {
    display: none;
  }
  .MuiButton-root {
    flex: 1;
    margin: 0 0.5rem;
  }
}

.order-alert {
  text-align: center;
}

.order-alert-success {
  color: #1dac00;
  background-color: #1dac002d;
  border-color: #1dac006b;
  text-align: center;
  font-weight: bold;
}

.order-alert-warning {
  color: #ac001d;
  background-color: #ac001d2d;
  border-color: #ac001d6b;
  text-align: center;
  font-weight: bold;
}

.order-alert-info  {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.order-delivery-info {
  margin-bottom: 1rem;

  p {
    margin-bottom: 0rem !important;
  }
}

.warning-text {
  color: #ac001d;
  text-decoration: line-through;
}

.providerCol {
  width: 85px;
  text-align: center;
}
.order-list-image {
  display: flex;
  justify-content: flex-end;
}
