.theme-item {
  cursor: pointer;
  h6 {
    transition: color 0.2s;
  }
  .item-image {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    transition: box-shadow 0.2s;
    position: relative;
  }
  &.active {
    color: $primary;
    .item-image {
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075), inset 0 0 0 1px $primary;
      &:after {
        content: '\e90c';
        @extend .icon;
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
        background-color: rgba(white, 0.95);
        border-radius: 20px;
      }
    }
  }
}
