// Settings Pages Style.

.settings-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  .nav-link {
    color: inherit;
    &.active {
      background-color: $bg-main;
    }
  }
  .settings-page-content {
    flex: 1;
    background-color: $bg-main;
    border: 1px solid $border-color;
    border-top: 0;
    border-radius: 0 0 $border-radius $border-radius;
    padding: 1.5rem 1rem;
  }
  .settings-page-content-header {
    padding: 0.5rem 1rem;
    background-color: $bg-base;
    border-bottom: 1px solid $border-color;
    margin: -1.5rem -1rem 1.5rem;
    display: flex;
    min-height: 60px;
    align-items: center;
    justify-content: space-between;
    h5 {
      margin: 0;
    }
  }
}

.hours-table {
  margin-bottom: 20px;
  margin-bottom: 0;
  white-space: nowrap;
  border-radius: $border-radius;
  overflow: hidden;
  td {
    vertical-align: middle;
  }
  tr {
    &:first-child {
      td {
        border-top: 0;
      }
    }
  }
  .hours-col {
    width: 100%;
    padding: 0;
  }
  .hours-date-input {
    width: 100px;
    padding: 0;
    border: 0;
    box-shadow: none;
  }
  .day-hours-label {
    padding-right: 30px;
    justify-content: flex-end;
    display: flex;
    label {
      margin: 0 4px;
      padding: 0 0.5rem;
      width: 100px;
    }
  }
  .day-hours-values {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .input-group {
      margin: 4px;
      width: auto;
      min-width: 84px;
      justify-content: center;
      border: 1px solid $border-color;
      background-color: $input-bg;
      border-radius: $input-border-radius;
    }
    .form-control {
      margin: 0 4px;
      border: 0;
      flex: 1;
      box-shadow: none;
      appearance: none;
      max-width: 40px;
      text-align-last: center;
    }
    .btn {
      min-width: 30px;
      background-color: #ecf0f6;
      border-radius: 6px;
      svg {
        margin: 0;
      }
    }
  }
  .action-col {
    // padding: 0 ;
    .btn {
      white-space: nowrap;
      box-shadow: none;
    }
  }
}

// Events Settings Style.

.events-settings,
.printer-settings {
  .copy-input {
    width: 40px;
    padding: 0.15rem 0.15rem;
    text-align: center;
    margin: auto;
  }
  .check-active {
    width: 20px;
    height: 20px;
    margin-left: 0;
    position: relative;
  }
  .checkbox-input {
    .form-check {
      padding-left: 0;
    }
  }
  .edit-title,
  .list-title {
    font-size: 20px;
    font-weight: bold;
  }
  .edit-table {
    .edit-thead {
      background-color: #f3f5f8;
    }
    .form-group {
      margin-bottom: 0;
    }
  }
  .event-list {
    tr:nth-of-type(odd) {
      background-color: #f3f5f8;
    }
    .table {
      border: 2px solid #e4e7ed;
    }
    thead {
      th {
        border-bottom: 16px solid #ffffff;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: none;
  }
  .table {
    thead th {
      border-bottom: none;
    }
  }
  .delete-btn {
    padding: 0.37rem;
    border: 2px solid #4290eb;
  }
  .table th,
  .table td {
    height: 66px;
    padding: 24px 8px;
    vertical-align: revert;
  }
  .check-default {
    position: relative;
    bottom: 4px;
  }
  .manually-btn {
    display: flex;
    justify-content: flex-end;
  }
  .manually-add {
    display: flex;
    justify-content: flex-end;
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    opacity: 1;
    background-color: #e9ecef;
    border-color: #e9ecef;
    color: #495057;
  }
  .btn .disabled,
  .btn:disabled {
    opacity: 1;
    background-color: #e9ecef;
    border-color: #e9ecef;
    color: #495057;
  }
  .th-border {
    border-bottom: 4px solid #71acf0;
  }
}

.business-divider {
  width: 100%;
  height: 1px;
  background: #d8d8d8;
  margin-bottom: 24px;
  margin-top: 12px;
}

.working-hours,
.holiday-hours {
  background-color: #f7f9fc;
  th,
  td {
    padding: 20px 14px !important;
  }
}

.business-footer {
  padding: 0.75rem 0;
  .btn-update {
    width: 90px;
    height: 38px;
    font-size: 16px;
    border-radius: 6px;
    padding: 0.35rem;
  }
}

.holiday-hours {
  .hours-add {
    width: 90px;
    height: 38px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #00df94;
    color: #00df94;
    padding: 0.35rem;
    background-color: transparent;
    &active,
    &hover {
      border: 1px solid #00df94;
      color: #00df94;
      border-color: #00df94;
      background-color: transparent;
    }
  }
  .btn-success:not(:disabled):not(.disabled):active,
  .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #00df94;
    background-color: #eff1f4;
    border-color: #00df94;
    outline: none;
  }
}
.highlighted {
  background-color: #c8e9ef !important;
}

// Report Page Style.

#pagination {
  .MuiMenu-paper {
    top: 91px !important;
    transform-origin: 0px 35px !important;
  }
  p {
    margin-bottom: 0;
  }
}
.report-page {
  .settings-page-content-header {
    padding: 0 15px;
  }
  .report-card {
    margin-top: 20px;
  }
  .line {
    height: 70px;
  }
  .report-group,
  .report-list {
    padding: 25px 15px 0 15px;
  }
  .report-group {
    .select-error {
      color: #f44336;
      margin: 0;
      font-size: 0.75rem;
      margin-top: 3px;
      text-align: left;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      line-height: 1.66;
      letter-spacing: 0.03333em;
    }
  }
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
  .banner-btn {
    padding: 5px 16px;
    font-size: 16px;
    text-transform: capitalize;
  }
  .disable-view {
    color: #f8f9fa !important;
    opacity: 0.6;
  }
  .banner-filter {
    .MuiButton-textPrimary {
      color: #307bff;
    }
  }
  .group-btn {
    .MuiButton-containedPrimary {
      background-color: #007bff;
    }
    .MuiButton-outlinedPrimary {
      color: #007bff;
      border: 1px solid #007bff;
    }
  }
  .MuiButton-textPrimary {
    color: #007bff;
  }
  .report-list {
    .MuiButton-textPrimary {
      color: #007bff;
    }
  }
  .report-table {
    .report-head {
      .WithStyles\(ForwardRef\(TableCell\)\)-head-6 {
        background-color: #f3f5f8;
        color: #232b46;
        font-size: 16px;
        border-bottom: 4px solid #71acf0;
      }
    }
    .report-body {
      .WithStyles\(ForwardRef\(TableCell\)\)-body-7 {
        font-size: 16px;
      }
      .WithStyles\(ForwardRef\(TableRow\)\)-root-8:nth-of-type(odd) {
        background-color: #f3f5f8;
      }
    }
  }
  .render-title {
    color: #6c757d !important;
  }
}

.MuiMenu-list {
  .report-title {
    padding: 2px 16px;
    font-size: 12px;
    color: #adb5bd;
  }
}

.report-dialog {
  .filter-title {
    color: #495057;
  }

  .MuiButton-outlinedPrimary {
    color: #007bff !important;
    border: 1px solid #007bff !important;
  }
}

.delete-filter-group {
  .WithStyles\(ForwardRef\(DialogActions\)\)-root-48 {
    padding: 16px !important;
  }
}

.dialog-button {
  .MuiButton-containedPrimary {
    color: #fff;
    background-color: #007bff;
  }
  .MuiButton-outlinedPrimary {
    color: #007bff;
    border: 1px solid #007bff;
  }
  .MuiButton-containedPrimary:hover {
    background-color: #007bff;
  }
  .banner-btn {
    padding: 5px 16px;
    font-size: 16px;
    text-transform: inherit;
    width: 80px;
  }
}

// Admin Button.

.changeAdmin-modal {
  margin-top: 200px;
}

.admin-disabled {
  padding-right: 20px;
  .disable-admin {
    border: 1px solid #df4250;
    border-radius: 6px;
    font: normal normal normal 16px Roboto;
    letter-spacing: 0px;
    color: #dd3c4b;
    opacity: 1;
    &:hover {
      color: #ffffff;
    }
  }
  .enable-admin {
    background: var(--unnamed-color-007bff) 0% 0% no-repeat padding-box;
    background: #007bff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    font: normal normal normal 16px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
  }
}

// .MuiDialog-paperWidthSm {
//   width: 420px !important;
//   .Component-root-15 {
//     padding: 30px !important;
//   }
//   .MuiFormControl-root {
//     width: 100%;
//   }
// }

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #007bff !important;
}

.MuiPickersCalendar-transitionContainer {
  margin-bottom: 12px;
}

.MuiFormLabel-root {
  color: #6c757d !important;
}

.MuiTableHead-root {
  .MuiTableCell-root {
    border-bottom: 2px solid #71acf0 !important;
  }
}

.MuiSelect-select.MuiSelect-select {
  color: #2c405a !important;
}

.MuiTableCell-root {
  border-bottom: 1px solid #ecf0f6 !important;
}

.WithStyles\(ForwardRef\(TableRow\)\)-root-14:nth-of-type(odd) {
  background-color: #f3f5f8;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #ced4da !important;
}

.brandLocationsModal {
  .modal-footer {
    border: none;
  }
  .locationModalText {
    margin-top: -14px;
    margin-left: 5px;
    font-size: 15px;
  }
}
.report-group {
  .MuiInputLabel-shrink {
    transform: translate(0, -1.5px) scale(0.75);
  }
}

.icon-download {
  cursor: pointer;
  &:hover {
    color: #2c405abd;
  }
}
