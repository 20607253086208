.orders-page {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: -24px;
  margin-left: -32px;
  margin-right: -32px;
  .order-content {
    flex: 1;
  }
}

.order-filter {
  position: sticky;
  top: 0;
  .filter-head {
    flex-direction: row;
    align-items: center;
    display: flex;
    padding: 0 12px;
    // height: 3.75rem;
    box-shadow: $box-shadow-sm;
    background-color: $bg-main;
  }
  .filter-collapse {
    flex-direction: row;
    align-items: center;
    padding: 1rem 0;
    border-bottom-width: 1px;
    border-bottom-color: $border-color;
    background-color: $bg-main;
  }
  .active {
    color: #3b4452;
  }
}
.order-status {
  display: flex;
  align-items: center;
  .status-icon {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: $border-radius;
  }
  p {
    margin: 0;
  }
}
.order-list {
  // display: flex;
  // flex: 1;
  .order-price {
    strong {
      color: #007d4a;
    }
  }

  .statusBlock {
    display: flex;
    flex-direction: column;
  }
  .statusIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
  }
  .statusIconRow {
    display: flex;
  }
  th,
  td {
    padding: 12px;
  }
  .active {
    background-color: rebeccapurple;
  }
  // .order-tr th,
  // .order-tr td {
  //   padding: 24px;
  // }
}
.order-th {
  th,
  td {
    padding: 24px 12px;
  }
}

.order-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}
// .orders-content-wrapper {
//   display: flex;
//   flex: 1;
// }
.orders-table {
  th {
    border-top: 0;
    position: sticky;
    top: 60px;
    background-color: white;
    z-index: 22;
    box-shadow: 0 1px 0 0 $border-color, inset 0 1px 0 0 $border-color;
  }
  tr {
    height: 70px;
  }
  td {
    vertical-align: middle;
  }
}

.report-contacts-table {
  font-size: 0.875rem;
  td {
    vertical-align: top;
    + td {
      padding-left: 0.5rem;
    }
  }
}
.report-printout-table {
  font-size: 0.875rem;
  width: 100%;
  line-height: 1.2;
  td {
    padding: 0.375rem 0;
    &:last-child {
      text-align: right;
    }
  }
  .total-row {
    font-size: 1rem;
  }
}

// Order confirmatino modal
.confirm-order {
  .modal-content {
    width: auto;
  }
}

// .pickup-items-cont {
//   display: flex;
//   justify-content: space-between;
//   margin: 40px 0;
//   .pickup-item {
//     cursor: pointer;

//     display: flex;
//     align-items: center;
//     flex-direction: column;

//     box-shadow: 0 0 4px #23232369;
//     padding: 20px 25px;
//     margin: 0px 7px;

//     span {
//       pointer-events: none;
//     }

//     &.active {
//       border: 1px solid #007bff;
//       background-color: #007bff1c;
//     }
//   }
//   p {
//     font-weight: bold;
//   }
// }

.action-orders {
  margin-right: 12px;
}
