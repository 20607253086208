.menu-tab-nav {
  display: flex;
  align-items: center;
  max-height: 3.25rem;
  border-bottom: 1px solid $border-color;

  .menu-tab-nav-scroll {
    flex: 1;
    display: flex;
    align-self: stretch;
    align-items: stretch;
    overflow: auto;
    margin-bottom: -1px;
    padding-top: 0.5rem;
  }
  .menu-tab-item {
    padding-right: 15px;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 40px;
      top: 0;
      right: -6px;
      border-radius: 0 $border-radius 0 0;
      border: 1px solid $border-color;
      border-left: 0;
      z-index: -1;
      transform: skewX(15deg);
      background-color: white;
    }
    .item-inner {
      height: 44px;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      border: 1px solid $border-color;
      border-right: 0;
      border-radius: $border-radius 0 0 0;
      background-color: white;
      color: $text-muted;
      text-transform: capitalize;
      font-weight: 500;
    }
    &.active {
      &:after,
      .item-inner {
        border-bottom-color: $body-bg;
        background-color: $body-bg;
      }
    }
  }
  .item-close {
    position: absolute;
    top: -0.75rem;
    right: -0.75rem;
    z-index: 22;
    padding: 0.25rem;
    color: $body-color;
  }
  .item-image {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    object-fit: contain;
  }
  .item-name {
    flex: 1;
  }
}
