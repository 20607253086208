.nav-item-draggable {
  .overview-items {
      background-color: $bg-bulk;
      border-left: 3px solid $border-bulk;
      &:hover {
          background-color: $bg-selected-and-hover-bulk;
      }
  }
  #overview-selected {
      background-color: $bg-selected-and-hover-bulk;
  }
}

.update-icon {
  font-size: 20px !important;
  width: 1.2em;
  height: 1.25em;
}
