.form-content-actions {
  margin-top: 1rem;
  padding: 0.5rem 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  &:empty {
    display: none;
  }
}
.form-actions {
  margin-top: 1rem;
  .btn {
    min-width: 120px;
    margin-right: 1rem;
  }
}
.form-group > label {
  line-height: 1;
}

.form-group {
  &.text-danger {
    position: relative;
  }
  .invalid-feedback {
    position: absolute;
    right: 0;
    text-align: right;
    top: 100%;
    font-size: 0.75em;
    line-height: 1;
  }
}
fieldset.form-group {
  .invalid-feedback {
    position: static;
  }
}

.label-tooltip {
  display: flex;
  align-items: center;
  i {
    font-size: 1.2rem;
    vertical-align: top;
    display: inline-block;
    margin-top: -3px;
  }
}
.tooltip-inner {
  box-shadow: $box-shadow-sm;
}

.custom-control-label {
  .label-tooltip {
    i {
      margin-top: 1px;
    }
  }
}

// form {
//   &.is-invalid,
//   &.av-invalid {
//     button[type='submit'] {
//       pointer-events: none;
//       opacity: 0.65;
//     }
//   }
// }

.modifiers-list .form-actions {
  position: sticky;
  bottom: 0;
  width: 100%;
  text-align: revert;
  display: flex;
  justify-content: flex-end;
  background: white;
  border-top: 1px solid #e2ecf2;
  padding: 10px;
}
