.menu-modal {
  width: 100%;
  min-height: 80vh;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 22;
  }
  .modal-body {
    justify-content: center;
  }
  .modal-body,
  .tab-content,
  .modal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .modal-content {
    @media (min-width: 1200px) {
      width: 71%;
    }
  }

  .pane-content-wrapper {
    align-items: center;
    justify-content: center;
  }
  .pane-content-header {
    max-width: 390px;
  }
  .menu-logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  .select-items,
  .menu-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 368px;
    margin: 20px auto;
  }

  .select-item,
  .menu-item {
    text-align: left;
    height: 56px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0 1rem;
    width: 100%;

    .item-name {
      text-transform: capitalize;
      flex: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .menu-item {
    margin-bottom: 0.5rem;
    box-shadow: 0 6px 8px 0 rgba(203, 203, 203, 0.3);
    border-radius: $border-radius;
    background: $bg-main;

    .item-logo {
      width: 40px;
      height: 40px;
      object-fit: contain;
      margin-right: 1rem;
      border: 1px solid $border-color;
      border-radius: 10px;
    }
    .linked-text {
      color: $text-muted;
      font-size: 14px;
      i {
        font-size: 16px;
      }
    }
    .item-arrow {
      color: $text-muted;
      font-size: 26px;
      margin-left: 1rem;
    }
  }
  .select-items {
    width: 256px;
    margin: 1.5rem auto;
    border: 1px solid $border-color;
  }
  .select-item {
    border-bottom: 1px solid $border-color;
    &:last-child {
      border-bottom: 0;
    }
    .item-check {
      width: 24px;
      height: 24px;

      border: 1px solid $border-color;
      border-radius: 24px;
    }
    .icon-check {
      font-size: 24px;
    }
  }

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h5 {
      margin-bottom: 0;
    }
    .divider {
      margin-right: 1rem;
      height: 1.25rem;
      border-left: 1px solid $border-color;
    }
  }
  .custom-control {
    max-width: 400px;
    margin: auto;
    border-top: 1px solid $border-color;
    padding-top: 1rem;
    text-align: left;
    margin-bottom: 1rem;
  }
  .action-btn {
    width: 140px;
    margin: 0 0.5rem;
  }
  .warning-icon {
    font-size: 44px;
  }
}
