$success: #2ecc71;
$border-color: #c5d9e8;
$border-light: rgba($border-color, 0.5);
$bg-base: #f6f7fb;
$bg-main: white;
$bg-dark: #3c4351;
$bg-select: #f5e5d3;

// Bulk colors
$bg-selected-and-hover-bulk: #FFE5A1;
$bg-bulk: #FFF4D8;
$border-bulk: #FCB400;
//............................................//

$body-bg: $bg-base;
$body-color: #2c405a;
$text-inverse: white;
$text-muted: #657786;

$link-hover-decoration: none;
$grid-gutter-width: 16px;
$border-radius: 6px;

//............................................//
//...............  TYPOGRAPHY  ...............//
//............................................//

$font-family-base: 'Roboto', sans-serif;

$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.8125;

$btn-font-weight: 500;
$link-hover-decoration: none;

$sidebar-bg: $bg-dark;
$sidebar-bg-active: #222933;
$sidebar-color: $text-inverse;
$sidebar-width: 200px;

$table-cell-padding-sm: 0.5rem;
$table-accent-bg: $bg-base;
$table-hover-bg: $bg-select;
$table-bg: $bg-main;

$tooltip-font-size: 0.9375rem;
$tooltip-max-width: 300px;
$tooltip-color: $body-color;
$tooltip-bg: #f4f4f4;
$tooltip-opacity: 1;
$tooltip-padding-y: 0.5rem;
$tooltip-padding-x: 0.75rem;

// $font-weight-normal: 400;
// $font-weight-medium: 500;
// $font-weight-bold: 700;
// $font-weight-bolder: 700;

// $font-weight-base: $font-weight-normal;

// $h1-font-size: $font-size-base * 3.052;
// $h2-font-size: $font-size-base * 2.441;
// $h3-font-size: $font-size-base * 1.953;
// $h4-font-size: $font-size-base * 1.563;
// $h5-font-size: $font-size-base * 1.25;
// $h6-font-size: $font-size-base;

// $headings-font-weight: $font-weight-normal;

:export {
    backgroundSelectedBulk: $bg-selected-and-hover-bulk;
    backgroundBulk: $bg-bulk ;
    borderBulk: $border-bulk;
}
