.auth-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-image: url(../../images/bg-auth.png);
  background-size: cover;
  padding: $grid-gutter-width;
  background-color: $bg-main;
}

.auth-box {
  background-color: #fff;
  border-radius: 6px;
  max-width: 420px;
  padding: $grid-gutter-width;
  width: 100%;
  margin-bottom: 10vh;
}

.box-content {
  margin-top: 80px;
  .content-text {
    font-size: 18px;
    color: #2c3f5ab2;
  }
  .content-img {
    margin: 0 8px 0 24px;
  }
}

.signup-wizard {
  align-self: stretch;
  width: 100%;
  max-width: 768px;
  margin: auto;
  .wizard-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  .check-img {
    width: 36px;
    height: 36px;
  }
  .tab-three {
    .form-check-input {
      width: 16px;
      height: 16px;
      margin-top: 0.18rem;
      margin-left: -1.5rem;
    }
  }
}

.check-img {
  width: 36px;
  height: 36px;
}

.wizard-steps {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wizard-step {
  align-items: center;
  display: flex;

  + .wizard-step {
    margin-left: 1rem;
  }

  .step-button,
  .step-number {
    min-width: 2rem;
    height: 2rem;
    display: block;
    border-radius: 2rem;
    line-height: 2rem;
    margin-right: 1rem;
  }
  .step-number {
    background-color: #cdcdcd;
    font-weight: 700;
    color: white;
  }

  .success-step {
    color: white;
    background-color: $success !important;
  }
  .step-button {
    background-color: #cdcdcd;
    border: 0;
    padding: 0;
    color: white;
    min-width: 2rem;
    height: 2rem;
    display: block;
    border-radius: 2rem;
    line-height: 2rem;
    margin-right: 1rem;
    box-shadow: none;

    // i {
    //   font-size: 18px;
    //   vertical-align: top;
    //   display: inline-block;
    //   margin-left: 2px;
    //   margin-top: 6px;
    // }

    // &.active {
    //   background-color: $primary;
    //   color: white;
    // }
  }
  // .step-label {

  // }
}
// .active-step {
//   // background-color: $primary;
//   color: white;
//   min-width: 2rem;
//   height: 2rem;
//   display: block;
//   border-radius: 2rem;
//   line-height: 2rem;
//   margin-right: 1rem;
// }

.wizard-content {
  max-width: 296px;
  margin: auto;
}
.wizard-footer {
  text-align: right;
  .btn {
    min-width: 120px;
    margin-left: 1rem;
  }
}
.billingTitle {
  font-size: 18px;
  margin: 0;
}
.wizard-finish {
  text-align: center;
  margin: auto;
  .wizard-title {
    margin-top: 10px;
    font-size: 36px;
    font-weight: 400;
  }
  .wizard-subtitle {
    margin-bottom: 115px;
    font-weight: bold;
    font-size: 36px;
  }
  .wizard-info {
    margin-bottom: 20px;
  }
}
.subscription-plans {
  .form-check {
    padding: 0;
    height: 100%;
  }
  .form-check-label {
    height: 100%;
  }
  input {
    display: none;
    &:checked + label .subscription-plan {
      border-color: $success;
      position: relative;
      box-shadow: inset 0 0 0 1px $success;
      &:before {
        @extend .icon;
        content: '\e910';
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
        z-index: 2;
        color: $success;
        background-color: #fff;
        border-radius: 1.5rem;
        font-size: 1.5rem;
      }
    }
  }
  label {
    width: 100%;
  }
  .card-subscription {
    .plan-title {
      text-align: center;
      font-size: 28px;
      color: #2c405a;
    }
    .plan-options {
      font-size: 16px;
      color: #495057;
    }
    .item-footer {
      text-align: center;
      display: flex;
      flex-direction: column;
      .footer-price {
        line-height: 18px;
        font-size: 30px;
        color: #2c405a;
      }
      .footer-valuate {
        font-size: 14px;
        color: #6c757d;
      }
    }
    .item-price {
      margin-top: 10px;
      button {
        font-size: 16px;
        color: #ffffff;
        border-radius: 6px;
      }
      .btn-secondary {
        background-color: #6c757d;
        border-color: #6c757d;
      }
      .btn-primary {
        background-color: #007bff;
        border-color: #007bff;
      }
    }
  }
  .card-box {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #adb5bd;
    border-radius: 6px;
    opacity: 1;
  }
  .card-box-select {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-bbd4e2);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #bbd4e2;
    opacity: 1;
  }
  .invalid-feedback {
    margin-top: 10px;
  }
}
.subscription-plan {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  transition: all 0.3s;
  font-size: 14px;
  .item-header {
    padding: 0.5rem 0;
    h5 {
      margin: 0;
    }
  }
  .item-body {
    padding: 1rem 0;
    color: $text-muted;
    line-height: 1.833;
    flex: 1;
    ul {
      padding: 0;
      list-style-type: none;
    }
  }
}
.checkImg {
  margin-bottom: 45px;
}

.titleCongrats {
  margin-bottom: 115px;
  h2 {
    font-size: 36px;
    font-weight: 100;
  }
  h3 {
    font-size: 36px;
  }
}

.wizard-finish-brand {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.italic {
  font-weight: 600;
}
